<template>
    <div class="nav">
        <div class="container-fluid flex mx-auto">
            <div class="flex align-items-centers">
                <div class="logo-wrapper">
                    <span style="cursor:pointer" @click="openMyLoads()" >
                        <svg xmlns="http://www.w3.org/2000/svg"
                             width="60.783"
                             height="44.448"
                             viewBox="0 0 60.783 44.448"
                             class="logo">
                            <g id="g10" transform="translate(0 1985.089)">
                                <g id="g12" transform="translate(0 -1985.089)">
                                    <path id="path14"
                                          d="M2197.558-1979.166a5.922,5.922,0,0,1-5.922,5.922,5.922,5.922,0,0,1-5.922-5.922,5.922,5.922,0,0,1,5.922-5.922,5.922,5.922,0,0,1,5.922,5.922"
                                          transform="translate(-2136.775 1985.089)"
                                          fill="#ef4d43" />
                                    <path id="path16"
                                          d="M2272-1353.46a9.1,9.1,0,0,1-4.082-.963v30.326h8.163v-30.326a9.1,9.1,0,0,1-4.081.963"
                                          transform="translate(-2217.134 1368.544)" />
                                    <path id="path18"
                                          d="M0-1959.2v43.868H22.928v-8.166H8.163v-35.7H0"
                                          transform="translate(0 1959.775)" />
                                    <path id="path20"
                                          d="M539.332-1953.109c-4.177-4.023-9.62-6.087-17.56-6.087h-12.58v32.463h8.163v-24.347h4.482c4.487,0,8.228.55,10.818,3.343a15.163,15.163,0,0,1,3.73,10.411,15.119,15.119,0,0,1-3.835,10.419c-2.195,2.349-5.136,3.208-8.593,3.413v8.166c6.787-.352,11.608-2.49,15.384-6.134,4.139-4,6.142-9.481,6.142-15.864,0-6.242-2.065-11.848-6.15-15.783"
                                          transform="translate(-497.791 1959.775)" />
                                </g>
                            </g>
                        </svg>
                    </span>
                </div>
                <div>
                    <custom-router-link :to="{ name: 'Carriers Profile', params: { id: 'lookup' }}" newTab>
                        <Button
                            type="button"
                            label="Carrier Lookup"
                            icon="pi pi-search"
                            class="ml-6 shadow-3"
                            style="background: #014c5b; border: 1px solid #014c5b;"
                            role="link"
                        />
                    </custom-router-link>                   
                </div>
            </div>
            <notification-ribbon @closeAlert="closeAlert()"></notification-ribbon>
            <div class="flex">
                <div class="link-wrapper mr-10" v-if="isAuthed == true">
                    <ul class="nav-menu hidden  md:flex" ref="mobilemenu">
                        <li class="dropdown-submenu">
                            <a>
                                <span>Menu</span>
                                <svg class="icon-sm ml-2">
                                    <use href="/svg/icons.svg#chevron-down" xlink:href="/svg/icons.svg#chevron-down"></use>
                                </svg>
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <custom-router-link :to="{ name: 'My Loads'}" newTab>My Loads</custom-router-link>
                                </li>
                                <li>
                                    <custom-router-link :to="{ name: 'My Loads', params: { loadtype: 'Available' }}" newTab>Available</custom-router-link>
                                </li>
                                <li>
                                    <custom-router-link :to="{ name: 'My Loads', params: { loadtype: 'Covered' }}" newTab>Covered</custom-router-link>
                                </li>
                                <li>
                                    <custom-router-link :to="{ name: 'My Loads', params: { loadtype: 'Dispatched' }}" newTab>Dispatched</custom-router-link>
                                </li>
                                <li>
                                    <custom-router-link :to="{ name: 'My Loads', params: { loadtype: 'In-Transit' }}" newTab>In-Transit</custom-router-link>
                                </li>
                                <li>
                                    <custom-router-link :to="{ name: 'My Loads', params: { loadtype: 'OnHold' }}" newTab>On Hold</custom-router-link>
                                </li>
                                <li>
                                    <custom-router-link :to="{ name: 'EDI 204' }" newTab>EDI</custom-router-link>
                                </li>
                                <li>
                                    <custom-router-link :to="{ name: 'Book Edit' }" newTab>Create FTL</custom-router-link>
                                </li>
                                <li>
                                    <custom-router-link :to="{ name: 'Ltl' }" newTab>Create LTL</custom-router-link>
                                </li>
                                <li>
                                    <custom-router-link :to="{ name: 'Intermodal' }" newTab>Create Intermodal</custom-router-link>
                                </li>
                                <li>
                                    <custom-router-link :to="{name: 'Customer Profile - Index'}" newTab>Customer Profile</custom-router-link>
                                </li>
                                <li>
                                    <a @click.prevent="openCarrierSmartSearch" class="cursor-pointer">Carrier Smart Search</a>
                                </li>
                                <li v-if="HasFeature('DormantCustomerManager')">
                                    <custom-router-link  :to="{ name: 'Dormant Customer Manager' }" >Dormant Customer Manager</custom-router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex items-center">
                    <!-- <router-link v-if="isAuthed == false" to="/login" class="log-btn"
                      >Login</router-link
                    > -->
                    <div class="notification-dropdown-wrapper" v-if="isAuthed == true">
                        <section class="dropdown-menu-wrapper"
                                 :class="notificationCount == 0 ? 'no-border' : ''">
                            <button class="dropdown-menu-button" @click="notificationScroll">
                                <svg class="icon fill-svg icon-white">
                                    <use href="/svg/icons.svg#Notification" xlink:href="/svg/icons.svg#Notification"></use>
                                </svg>
                                <div class="circle-wrapper">{{ notificationCount }}</div>
                            </button>
                            <section class="dropdown right" v-show="notificationDropdown">
                                <div class="notification-holder" id="notificationholder">
                                    <section class="option notification-wrapper"
                                             v-for="(notification, index) in notifications"
                                             :key="notification.NotificationID">
                                        <div :class="+notification.Status ? 'read' : 'unread'">
                                            <h3>{{ notification.Subject }}</h3>
                                            <p>{{ notification.BodyText }}</p>
                                            <ldi-dropdown align="left">
                                                <template v-slot:button-content>
                                                    <svg class="icon icon-dots">
                                                        <use href="/svg/icons.svg#dots" xlink:href="/svg/icons.svg#dots"></use>
                                                    </svg>
                                                </template>
                                                <section class="option notification-wrapper"
                                                         @click="read(index)">
                                                    Read
                                                </section>
                                                <section class="option notification-wrapper"
                                                         @click="deleteNotification(index)">
                                                    Delete
                                                </section>
                                            </ldi-dropdown>
                                        </div>
                                    </section>
                                </div>
                                <section v-if="notifications.length > 0" class="clearall">
                                    <span @click="clearall">Clear All</span>
                                    <span @click="readall">Read All</span>
                                </section>
                            </section>
                        </section>
                    </div>
                    <ldi-dropdown align="right">
                        <template v-slot:button-content>
                            <svg class="icon icon-user icon-white">
                                <use href="/svg/icons.svg#user" xlink:href="/svg/icons.svg#user"></use>
                            </svg>
                            <span class="ml-2 username">{{ firstNameStr }}</span>
                        </template>

                        <section v-if="isAuthed == true"
                                 class="option notification-wrapper disabled-hover">
                            LDI Corporate
                        </section>
                        <section v-if="isAuthed == true"
                                 class="option notification-wrapper"
                                 @click="redirectToSettings">
                            Settings
                        </section>
                        <section class="option notification-wrapper" @click="OpenSignOutConfirmationDialog()">
                            Sign Out
                        </section>
                    </ldi-dropdown>
                    <Dialog v-model:visible="SignoutConfirmationFlag"
                            :style="{ width: '450px' }"
                            header="Confirm"
                            :modal="true"
                            class="theme-dialog">
                        <div class="confirmation-content">
                            <span>Are you sure you want to sign out?</span>
                        </div>
                        <template #footer>
                            <button class="theme-btn" @click="logOut">Yes</button>
                            <button class="btn-outline" @click="CloseSignOutConfirmationDialog()()">
                                No
                            </button>
                        </template>
                    </Dialog>
                </div>
            </div>

            <a href="javascript:void(0);"
               class="icon block md:hidden"
               @click="toggleMenu()">
                <svg class="icon">
                    <use href="/svg/icons.svg#menu" xlink:href="/svg/icons.svg#menu"></use>
                </svg>
            </a>
        </div>
    </div>
    <Dialog v-model:visible="showITSdialog"
            :style="{ width: '450px' }"
            header="Login to Truckstop"
            :modal="true"
            class="theme-dialog">
        <div class="confirmation-content">
            <div class="its-login-cont">
                <div>
                    <img src="@/assets/images/truckstop_logo2022.svg" class="truck-stop-logo" />

                </div>
                <div>
                    <div class="flex-cont">
                        <label>Username</label>
                        <input type="text"
                               class="form-control w-170"
                               placeholder="Enter Username"
                               v-model="itsUsername" />
                    </div>
                    <div class="flex-cont">
                        <label>Password</label>
                        <input type="text"
                               class="form-control w-170"
                               placeholder="Enter Password "
                               v-model="itsPassword" />

                    </div>
                    <div> <span>Forgot password? Click </span><a href="https://truckstop.com/" target="_blank">truckstop.com</a></div>

                </div>
            </div>
        </div>
        <div class="its-error-cont" v-if="hasITSLoginError">{{ITSloginError}}</div>
        <template #footer>
            <button class="theme-btn" @click="LoginITS()">Login</button>
            <button class="btn-outline" @click="closeITSLoginDialog()">
                Skip
            </button>
        </template>
    </Dialog>
    <Dialog v-model:visible="openLoad.dialog"
            :style="{ width: '450px' }"
            header="Open a Load"
            :modal="true"
            class="theme-dialog">
        <div class="confirmation-content">
            <div class="flex flex-column">

                <div class="p-inputgroup flex-1 my-1">
                    <span class="p-inputgroup-addon w-8rem bg-black-alpha-10">Load ID</span>
                    <InputNumber ref="openLoadIDInput"
                                 v-model="openLoad.LoadID"
                                 :useGrouping="false"
                                 placeholder="1234567"
                                 @keyup.enter="$refs.openLoadOpenBtn.click()" />
                </div>
                <div v-if="isLoadNotFound" class="text-red-600">*No load found matching that Load ID.</div>
            </div>
        </div>
        <template #footer>
            <button ref="openLoadOpenBtn"
                    class="theme-btn"
                    @click="handleOpenLoad">
                Open
            </button>
            <button class="btn-outline" @click="resetOpenLoad">Cancel</button>
        </template>
    </Dialog>
    <source-load-popup ref="sourceload"></source-load-popup>
</template>
<script>
import { dataProviderAAD } from "@/api/dataProviderAAD";
import { dataProvider } from "@/api/dataProvider";
import NotificationRibbon from "@/components/common/NotificationRibbon.vue";
import InputNumber from "primevue/inputnumber";
import CustomRouterLink from "./CustomRouterLink.vue";
import sourceLoadPopup from "@/components/my-loads/sourceLoadPopup.vue";

export default {
  data() {
    return {
      SignoutConfirmationFlag: false,
      navData: this.$store.state.login.MenuList,
      notificationCount: 0,
      notifications: [],
      alerts: [],
      pageNumber: 1,
      readNotificationIds: [],
      notificationDropdown: false,
        showITSdialog: false,
        itsUsername: '',
        itsPassword: '',
        ITSloginError: '',
        hasITSLoginError: false,
        openLoad: {
            dialog: false,
            LoadID: null
        }

    };
  },
  mounted() {
    this.getNotificationCount();
    this.getNotificationsPageLoad(this.pageNumber, 4);
    document.addEventListener("click", this.close);
    this.getAlerts();

       this.emitter.on("itsCredsShow",()=>{
           this.showITSdialog = true;
    });
    // let createftl = document.querySelector(".create-ftl");
    // createftl.addEventListener("click", (event) => {
    //   event.preventDefault();
    //   alert("Ds");
    // });

      document.addEventListener('keydown', e => {
          if (e.ctrlKey && e.key.toLocaleLowerCase() === 'o') {
              e.preventDefault();
              this.openLoad.dialog = true;
              this.$nextTick(function () { // use non-arrow func for proper this binding (don't replace this with arrow func unless you know what you are doing)
                  this.$refs.openLoadIDInput.$el.querySelector('input').focus();
              });
          }
      });
  },
  components: {
      NotificationRibbon,
      InputNumber,
      CustomRouterLink,
      sourceLoadPopup,
  },
        methods: {
            HasFeature(feature){
              

        return  this.$store.state.features.features.includes(feature);
    },
            openCarrierSmartSearch()
            {
                this.$refs.sourceload.openSourceLoadfromMenu();
            },
            async openMyLoads(){
                // BookEdit pages must be unlocked before navigation
                if (this.$route.name === 'Book Edit') {
                    this.emitter.emit("logoClicked");
                } else {
                    if (window.opener) { // If the ref to the original window still exists, close this window
                        window.close();
                    }
                    await this.$router.push({ name: 'My Loads' });
                    window.location.reload(); // Refresh needs to be manually triggered for child routes e.g. my-loads/available
                }
                
                
            },
            async handleOpenLoad() {
                await dataProvider
                    .IsLoadExists(this.openLoad.LoadID)
                    .then((data) => {
                        this.isActive = true;
                        if (data.IsSuccess) {
                            this.isLoadNotFound = false;
                            if (data.Data.LoadType == 0) {
                                window.open(`/Ltl/${this.openLoad.LoadID}`, '_blank');
                            }
                            else {
                                window.open(`/book-edit/${this.openLoad.LoadID}`, '_blank');
                            }
                            this.resetOpenLoad();
                        }
                        else {
                            this.isLoadNotFound = true;
                        }
                    });
                this.isActive = false;
            },
            resetOpenLoad() {
                this.$refs.openLoadIDInput.$el.querySelector('input').blur(); // we need to remove focus before hiding dialog or error will be thrown
                this.openLoad = {
                    dialog: false,
                    LoadID: null
                };
                this.isLoadNotFound = false;
            },
      async LoginITS() {

        this.hasITSLoginError = false;

        var portalID = this.$store.state.login.portalID;
        var employeeID = this.$store.state.login.employeeID;

          await dataProvider.loginToITS(employeeID, portalID, this.itsUsername, this.itsPassword)
              .then((res) => {
                  //if succesful login here close dialog
                  if (res.IsSuccessful === true) {
                      this.showITSdialog = false;
                  } else {
                      this.hasITSLoginError = true;
                      this.ITSloginError = JSON.parse(res.Error).error;

                  }
              });

      },
      closeITSLoginDialog() {
          this.showITSdialog = !this.showITSdialog;
      },
    OpenSignOutConfirmationDialog(){
      this.SignoutConfirmationFlag = true;
    },
    CloseSignOutConfirmationDialog()
    {
      this.SignoutConfirmationFlag = false;
    },
    async logOut() {
      this.$store.commit("login/clearLogin");
      this.isAuthed = false;
      localStorage.setItem("isAuthed",false);
      let logoutUrl = await dataProviderAAD.logoutUrl();
      window.open(logoutUrl, "_self");
      return true;
    },
    toggleMenu() {
      this.$refs.mobilemenu.classList.toggle("navbar-collapse");
    },
    redirectToSettings() {
      this.$router.push("/settings");
    },
    async getNotificationCount() {
      let result = await dataProvider.getNotificationCount();
      this.notificationCount = result;
    },
    async getNotifications(pageNumber, pageSize) {
      let result = await dataProvider.getNotifications(pageNumber, pageSize, 5);
      let notificationData = result.notificationData;
      Array.prototype.push.apply(this.notifications, notificationData);
      this.readNotificationIds = this.notifications.map((item) => {
        return item.NotificationID;
      });
      // await dataProvider.updateNotifications(this.readNotificationIds.join());
      return result;
    },
    async getNotificationsPageLoad(pageNumber, pageSize) {
      let result = await dataProvider.getNotifications(pageNumber, pageSize, 5);
      let notificationData = result.notificationData;
      Array.prototype.push.apply(this.notifications, notificationData);
      return result;
    },
    async getAlerts() {
      let result = await dataProvider.getNotifications(this.pageNumber, 4, 2);
      let notificationData = result.notificationData;
      this.alerts = [];
      notificationData.forEach((element) => {
        if (!this.checkDuplicatealerts(element) & (element.Status == false)) {
          this.alerts.unshift(element);
        }
      });
      this.showAlert();
    },
    async notificationRefresh(pageNumber, pageSize) {
      let result = await dataProvider.getNotifications(pageNumber, pageSize, 5);
      let notificationData = result.notificationData;
      if (notificationData.length > 0) {
        notificationData.forEach((element) => {
          if (!this.checkDuplicatealerts(element)) {
            this.notifications.push(element);
          }
        });
      }
    },
    checkDuplicatealerts(element) {
      if (this.alerts.length > 0) {
        for (let i = 0; i < this.alerts.length; i++) {
          if (element.NotificationID == this.alerts[i].NotificationID) {
            return true;
          }
        }
        return false;
      }
    },
    showAlert() {
      if (this.alerts.length > 0) {
        this.$toast.removeGroup("noti");
        let summary = this.alerts[this.alerts.length - 1].Subject;
        this.$toast.add({
          severity: "warn",
          summary: summary,
          group: "noti",
        });
      }
      if (
        (this.alerts.length == 0) &
        (this.pageNumber <= this.notificationCount / 4)
      ) {
        this.pageNumber++;
        this.getNotifications(this.pageNumber, 4);
      }
    },
    async closeAlert() {
      if (this.alerts.length > 0) {
        this.notificationCount = await dataProvider.updateNotifications(
          this.alerts[this.alerts.length - 1].NotificationID,
          "read"
        );

        let notificationIdIndex = this.notifications.findIndex(
          (item) =>
            item.NotificationID ==
            this.alerts[this.alerts.length - 1].NotificationID
        );
        if (notificationIdIndex !== -1) {
          this.notifications[notificationIdIndex].Status = true;
        }
        this.alerts.pop(this.alerts.length - 1);
        this.showAlert();
      }
    },
    notificationScroll() {
      this.notificationDropdown = !this.notificationDropdown;
      var dropdown = document.getElementById("notificationholder");
      dropdown.addEventListener("scroll", () => {
        if (
          dropdown.offsetHeight + dropdown.scrollTop >=
          dropdown.scrollHeight - 3
        ) {
          this.pageNumber++;
          this.getNotifications(this.pageNumber, 4);
        }
      });
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.notificationDropdown = false;
      }
    },
    async read(index) {
      let notificationId = this.notifications[index].NotificationID;
      this.notifications[index].Status = true;
      this.notificationCount = await dataProvider.updateNotifications(
        notificationId,
        "read"
      );
      this.$toast.removeGroup("noti");
      // this.notificationRefresh(this.pageNumber, 4);
      this.getAlerts();
    },
    async deleteNotification(index) {
      let notificationId = this.notifications[index].NotificationID;
      let notificationIdIndex = this.notifications.findIndex(
        (item) =>
          item.NotificationID == this.notifications[index].NotificationID
      );
      this.notificationCount = await dataProvider.updateNotifications(
        notificationId,
        "clear"
      );

      this.notifications.splice(notificationIdIndex, 1);
      this.$toast.removeGroup("noti");
      // this.notificationRefresh(this.pageNumber, 4);
      this.getAlerts();
    },
    async clearall() {
      this.notificationCount = await dataProvider.updateNotifications(
        0,
        "clearall"
      );
      this.notifications = [];
      this.alerts = [];
      this.$toast.removeGroup("noti");
      // this.notificationRefresh(this.pageNumber, 4);
      this.getAlerts();
    },
    async readall() {
      this.notificationCount = await dataProvider.updateNotifications(
        0,
        "readall"
      );
      this.alerts = [];
      this.$toast.removeGroup("noti");
      this.notifications.forEach((item) => {
        item.Status = true;
      });
      // this.notificationRefresh(this.pageNumber, 4);
      this.getAlerts();
    },
    openCreateLoad() {
      // this.$store.commit("login/openCreateLoad");
    },
  },
  computed: {
   
    isAuthed() {
      return this.$store.state.login.authed == true ? true : false;
    },
    firstNameStr() {
      var firstNameStr = this.$store.state.login.firstName;
      if (firstNameStr != null && firstNameStr != "") {
        firstNameStr = firstNameStr.toLocaleLowerCase();
      }
      return firstNameStr;
    },
  },

  beforeUnmount() {
    document.removeEventListener("click", this.close);
  },
};
</script>
<style lang="scss">
    .its-error-cont{
        display:flex;
        justify-content:center;
        color:red;
        padding:15px;
    }
    .flex-cont label {
        margin-right: 5px
    }
        .flex-cont {
            display: flex;
            justify-content: space-between;
            flex-direction: unset;
            align-items: center;
            padding: 5px
        }

        .its-login-cont {
            display: flex;
        }

        .truck-stop-logo {
            width: 10rem;
            padding: 10px
        }

        .nav {
            padding: 15px 0;
            box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);

            .flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .logo-wrapper {
                .logo {
                    width: 60px;
                    fill: #fff;
                }
            }

            .nav-menu {
                li {
                    a {
                        display: inline-flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 8px 12px;
                        width: 100%;
                        transition: all 0.2s ease-in;
                        color: #191919;

                        &:hover {
                            background: rgba(0, 0, 0, 0.3);
                        }

                        &.router-link-exact-active {
                            span {
                                display: inline-block;
                                padding: 4px 0;
                                font-weight: 500;
                                border-bottom: 2px solid #e74c42;
                            }
                        }
                    }

                    &.dropdown-submenu {
                        position: relative;
                        display: block;

                        .dropdown-menu {
                            display: none;
                            position: absolute;
                            background-color: #f9f9f9;
                            min-width: 170px;
                            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
                            z-index: 2;

                            li {
                                a {
                                    background: lighten($b-bg-primary, 10%);
                                    color: #fff;

                                    .icon-sm {
                                        transform: rotate(-90deg);
                                    }
                                }

                                .dropdown-menu {
                                    left: 100%;
                                    top: 0;
                                    opacity: 0;
                                    transition: all 0.2s ease-in;

                                    li {
                                        a {
                                            background: lighten($b-bg-primary, 10%);
                                            color: #fff;
                                        }
                                    }
                                }

                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                    }
                                }
                            }
                        }

                        &:hover {
                            .dropdown-menu {
                                display: block;
                            }
                        }
                    }
                }

                &.navbar-collapse {
                    display: block !important;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    background: #fafafa;
                    top: 60px;
                }
            }

            .dropdown-menu-wrapper {
                .dropdown-menu-button {
                    color: #fff;
                    padding: 8px 10px;
                    border: none !important;

                    .icon-user {
                        fill: #fff;
                    }
                }
            }

            .notification-dropdown-wrapper {
                .dropdown {
                    padding: 0;

                    .notification-holder {
                        min-width: 230px;
                        height: auto;
                        max-height: 170px;
                        overflow-x: hidden;
                        overflow-y: scroll;
                        padding: 0;

                        .option {
                            padding: 0;
                            // background: #046a7e;
                            border-bottom: 1px solid #3e8191;
                            transition: all 0.2s ease-in;

                            div {
                                padding: 6px 10px;
                                position: relative;
                            }

                            .read {
                                h3 {
                                    font-weight: 400;
                                    color: #9e9e9e;
                                }

                                p {
                                    color: #9e9e9e;
                                    font-weight: 400;
                                }
                            }

                            .unread {
                                h3 {
                                    font-weight: 500;
                                }

                                p {
                                    font-weight: 500;
                                }
                            }

                            h3 {
                                font-size: 14px;
                                margin-bottom: 5px;
                            }

                            p {
                                font-size: 11px;
                            }

                            .dropdown-menu-wrapper {
                                position: static;
                            }

                            .dropdown-menu-button {
                                position: absolute;
                                right: 3px;
                                top: 0px;
                                display: inline-block;
                                height: auto;
                            }

                            .dropdown {
                                top: 20px;
                                right: 12px;
                                background: transparent;
                                min-width: auto;

                                .menuArrow {
                                    display: none;
                                }

                                .option {
                                    width: 50px;
                                    background: #1d4859;
                                    color: #fff;
                                    padding: 4px 8px;
                                    font-size: 10px;
                                }
                            }
                            // &:hover {
                            //   background: rgba(0, 0, 0, 0.2);
                            //   box-shadow: 0px 2px 7px #ced4e2c2;
                            // }
                        }

                        a {
                            color: #fff;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .clearall {
                        padding: 4px 10px;
                        display: flex;
                        justify-content: space-between;
                        font-size: 13px;
                        color: #fff;

                        span {
                            cursor: pointer;
                        }
                    }
                }

                .circle-wrapper {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background: transparent;
                    border: 1.5px solid #ffff;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    position: absolute;
                    top: 0px;
                    right: 4px;
                }

                .dropdown-menu-button {
                    &:after {
                        display: none;
                    }
                }
            }



            .dropdown-menu-wrapper {
                .dropdown {
                    background: lighten($b-bg-primary, 10%);

                    .option {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        background: lighten($b-bg-primary, 10%);
                        color: #fff;

                        .icon-wrapper {
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 10px;
                        }

                        h3 {
                            font-size: 13px;
                            font-weight: 500;
                            width: 90%;
                        }

                        p {
                            font-size: 12px;
                        }
                        // &:hover {
                        //   background: rgba(0, 0, 0, 0.2);
                        // }
                        .icon-dots {
                            width: 15px;
                            height: 15px;
                        }
                    }

                    .header {
                        display: flex;
                        justify-content: space-between;
                        padding: 5px 15px;
                        font-size: 14px;
                        color: #fff;
                        border-bottom: 1px solid #f2f2f2;
                    }

                    .all-msg {
                        padding: 5px 15px;
                        font-size: 14px;
                        color: #fff;
                    }

                    &.right {
                        .menuArrow {
                            background: lighten($b-bg-primary, 10%);
                            border: none;
                        }
                    }
                }

                .dropdown-menu-button {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .nav .dropdown-menu-wrapper .dropdown .option {
            border-bottom: 1px solid #3e8191;
        }

        .nav .nav-menu li.dropdown-submenu .dropdown-menu li a {
            border-bottom: 1px solid #3e8191;
        }

        .log-btn {
            background: #014c5b;
            color: #fff;
            width: 200px;
            padding: 8px 35px;
            font-size: 14px;
            text-transform: uppercase;
            border-radius: 6px;
            margin-bottom: 20px;
            transition: all 0.3s ease-in-out;
        }

        .user-icon {
            width: 25px;
            display: inline-block;
            margin-right: 10px;
            fill: #fff;
        }

        .logout {
            color: #fff;
        }

        .username {
            text-transform: capitalize;
        }

        .dropdown-menu-wrapper .dropdown .option.notification-wrapper:hover {
            background: rgba(0, 0, 0, 0.2);
        }

        .theme-white {
            .nav {
                .logo-wrapper {
                    .logo {
                        fill: #204859;
                    }
                }
            }

            .nav .nav-menu li a {
                color: $dark-text-color;
            }

            .nav .nav-menu li.dropdown-submenu .dropdown-menu li a {
                background: #fff;
                color: #191919;
                border-bottom: 1px solid #e2e6ea;
            }

            .option {
                .icon.icon-dots {
                    fill: #1d4859;
                }
            }

            .user-icon {
                fill: $dark-text-color;
            }

            .fill-svg {
                fill: $dark-text-color;
            }

            .nav .nav-menu li.dropdown-submenu .dropdown-menu li .dropdown-menu li a {
                background: #fff;
                color: #181818;
            }

            .dropdown-menu-wrapper {
                .dropdown-menu-button {
                    .icon-user {
                        fill: #181818;
                    }


                    .icon-white {
                        fill: #1D4859 !important;
                    }
                }
            }

            .nav .notification-dropdown-wrapper .circle-wrapper {
                border: 1.5px solid #181818;
                color: #ef4d43;
            }
        }

        .nav .dropdown-menu-wrapper .dropdown .option:hover.disabled-hover:hover {
            background: transparent;
            cursor: default;
        }

        .theme-dark {
            .fill-svg {
                fill: #fff;
            }

            .nav .notification-dropdown-wrapper .circle-wrapper {
                color: #e74c42;
            }

            .nav .dropdown-menu-wrapper .dropdown,
            .nav .dropdown-menu-wrapper .dropdown .option,
            .nav .dropdown-menu-wrapper .dropdown.right .menuArrow {
                background: #292c2f;
            }

            .nav .dropdown-menu-wrapper .dropdown .option {
                border-bottom: 1px solid #888f95;

                &:last-child {
                    border: none;
                }
            }

            .nav .nav-menu li.dropdown-submenu .dropdown-menu li a {
                background: #2e2f3c;
                color: #fff;
                border-bottom: 1px solid #888f95;

                &:last-child {
                    border: none;
                }
            }

            .nav .notification-dropdown-wrapper .dropdown .notification-holder .option .dropdown .option {
                background: #4d5454;
                border-bottom: none;
            }

            .nav .notification-dropdown-wrapper .dropdown .clearall span {
                color: #fff;
            }
        }

        .theme-default {
            .fill-svg {
                fill: #fff;
            }

            .nav .notification-dropdown-wrapper .dropdown .clearall span {
                color: #fff;
            }
        }

        .fill-svg {
            fill: #fff;
        }

        @media (min-width: 992px) {
            .nav {
                .nav-menu {
                    li.dropdown-submenu {
                        a {
                            display: inline-flex;
                            color: #fff;
                        }
                    }
                }
            }

            .theme-white {
                .nav {
                    .nav-menu {
                        li.dropdown-submenu {
                            a {
                                display: inline-flex;
                                background: #fff;
                                color: #191919;
                            }
                        }
                    }
                }

                .nav .dropdown-menu-wrapper .dropdown-menu-button {
                    color: #191919;
                }

                .notification-dropdown-wrapper {
                    .dropdown {
                        &.right {
                            background: #fff;
                            color: #181818;

                            .menuArrow {
                                background: #fff;
                                border-left: 1px solid #eee;
                                border-top: 1px solid #eee;
                            }
                        }

                        .option {
                            background: #fff;
                            color: #181818;
                        }

                        .all-msg {
                            background: #fff;
                            color: #181818;
                        }

                        .header {
                            background: #fff;
                            color: #181818;

                            a {
                                color: $b-bg-primary;
                            }
                        }
                    }
                }

                .dropdown-menu-wrapper {
                    .dropdown {
                        border: 1px solid #e2e6ea;

                        &.right {
                            background: #fff;
                            color: #181818;

                            .menuArrow {
                                background: #fff;
                                border-left: 1px solid #eee;
                                border-top: 1px solid #eee;
                                display: none;
                            }
                        }

                        .option {
                            background: #fff;
                            color: #181818;
                            border-bottom: 1px solid #e2e6ea;
                        }

                        .all-msg {
                            background: #fff;
                            color: #181818;
                        }

                        .header {
                            background: #fff;
                            color: #181818;

                            a {
                                color: $b-bg-primary;
                            }
                        }
                    }
                }

                .nav .notification-dropdown-wrapper .dropdown .notification-holder .option .read {
                    h3,
                    p {
                        font-weight: 400;
                        color: #676767;
                    }
                }
            }

            .nav .notification-dropdown-wrapper .dropdown .clearall {
                color: #1d4859;
            }

            .theme-white .dropdown-menu-wrapper.no-border .dropdown {
                border: 1px solid #fff;
            }
        }
        // .theme-default .dropdown-menu-wrapper .dropdown .option:hover {
        //   background: darken(#046a7e, 10%) !important;
        // }
        .right .notification-holder::-webkit-scrollbar-track {
            background-color: #358696;
        }

        .right .notification-holder::-webkit-scrollbar {
            width: 6px;
            background-color: #358696;
        }

        .right .notification-holder::-webkit-scrollbar-thumb {
            background-color: #12606f;
        }

        .theme-white {
            .right .notification-holder::-webkit-scrollbar-track {
                background-color: #e4e8ec;
            }

            .right .notification-holder::-webkit-scrollbar {
                width: 6px;
                background-color: #cbd2d9;
            }

            .right .notification-holder::-webkit-scrollbar-thumb {
                background-color: #cbd2d9;
            }
        }

        .theme-dark {
            .right .notification-holder::-webkit-scrollbar-track {
                background-color: #292c2f;
            }

            .right .notification-holder::-webkit-scrollbar {
                width: 6px;
                background-color: #292c2f;
            }

            .right .notification-holder::-webkit-scrollbar-thumb {
                background-color: #cbd2d9;
            }

            .dropdown-menu-wrapper .dropdown .option.notification-wrapper {
                border-color: #000000;
            }

            .option {
                .icon.icon-dots {
                    fill: #FFFFFF;
                }
            }
        }


        .theme-white {
            .notification-dropdown-wrapper.dropdown.notification-holder.option .read {
                h3 {
                    font-weight: 400;
                    color: #676767;
                }

                p {
                    color: #676767;
                    font-weight: 400;
                }
            }

            .unread {
                h3 {
                    color: #1d4859;
                    font-weight: 500;
                }

                p {
                    color: #1d4859;
                    font-weight: 500;
                }
            }

            .dropdown-menu-wrapper .dropdown .option.notification-wrapper {
                border-color: #e4e8ec;
            }

            .nav .notification-dropdown-wrapper .dropdown .notification-holder .option .dropdown-menu-button {
                color: #1d4859;
            }
        }

        .theme-default {
            .option {
                .icon.icon-dots {
                    fill: #FFFFFF;
                }
            }
        }

        .theme-dark .dropdown-menu-wrapper .dropdown .option.notification-wrapper {
            border-color: black !important;
        }

</style>
